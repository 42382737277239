import React, { useState, useCallback, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./Registration.css";
import LoginIcon from "../../assets/img/user/registration-icon.png";
import HeadContent from "../../UI/HeadContent/HeadContent";
import Input from "../../UI/Input/Input";
import ReusableModal from "../../UI/Modal/Modal";
import { caesarDecrypt, caesarEncrypt } from "../../utils/Encryption";
import OTPModal from "../../UI/Modal/OTPModal/OTPModal";
import { useSelector } from "react-redux";
import ToastComponent from "../../UI/Toast/Toast";

export default function Registration() {
  const [modalShow, setModalShow] = useState(false);
  const dummyUserData = useSelector((state) => state?.dummyUser?.userDetails);
  const [toastData, setToastData] = useState({ show: false });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    reset,
  } = useForm();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [modalContent, setModalContent] = useState();
  const handleModalClose = useCallback(
    () => setModalContent({ ...modalContent, show: false }),
    []
  );
  const [otpModalData, setOtpModalData] = useState({
    show: false,
  });

  useEffect(() => {
    if (dummyUserData) {
      setValue("name", dummyUserData.name || "");
      setValue("lastname", dummyUserData.lastName || "");
      setValue("email", dummyUserData.email || "");
      setValue("contact", dummyUserData.contact || "");
      setValue("aadhar", dummyUserData.aadhar || "");
      setValue("pan", dummyUserData.pan || "");
    }
  }, [dummyUserData, setValue]);
  const otpModalClose = useCallback(() => {
    setOtpModalData({ ...otpModalData, show: false });
  }, []);

  const autocloseToast = () => {
    setTimeout(() => {
      setModalContent({ ...modalContent, show: false });
      setToastData({...toastData,show :false})
    }, 5000);
  };
  const registerHandler = useCallback(
    async (data) => {
      try {
        function splitFirstAndLastLetter(str) {
          if (str.length <= 2) {
            return "";
          }

          return str.slice(1, -1);
        }
        const datasting = JSON.stringify(data);
        const datass = splitFirstAndLastLetter(datasting);
        const encryptData = caesarEncrypt(datasting);
        const decreptdata = caesarDecrypt(encryptData);

        const response = await fetch(`${backendUrl}registration`, {
          method: "POST",
          body: `"${encryptData}"`,
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "fhyDNulWAg9NzBsLmw4Lf6J47pEhQI37w5rWVu9uF",
          },
        });
        const result = await response.json();
        if (result.status == "error") {
          // setModalContent({
          //   show: true,
          //   title: "Error",
          //   content: result.error,
          // });
          setToastData({
            show: true,
            type: "error",
            title: "Error",
            message: result?.error,
            className: "error-toast",
          });
          autocloseToast();
        } else if (result.status == "success") {
          setOtpModalData({
            show: true,
            sendInfo: result,
          });
          reset();
          autocloseToast();

        }
      } catch (error) {
        setToastData({
          show: true,
          type: "error",
          title: "Error",
          message: error,
          className: "error-toast",
        });

        // setModalContent({
        //   show: true,
        //   title: "Error",
        //   content: "Internal Server Error",
        // });
        autocloseToast();
        reset();

        console.error("Error parsing phone number:", error);
      }
    },
    [backendUrl]
  );

  // show,
  // title,
  // message,
  // onClose,
  // className,
  // type = "error",
  return (
    <>
      <ToastComponent
        show={toastData?.show}
        title={toastData?.title}
        message={toastData?.message}
        className={toastData?.className}
      />
      <div className="form-container red-light-bg p-5">
        <HeadContent className="text-center">
          <span className="fs-1">Create an </span>
          <span className="fs-1 red-head">Account</span>
        </HeadContent>
        <HeadContent className="text-center">
          <span className="fs-6">Already have an account? </span>
          <NavLink
            to="/auth/login"
            className="fs-6 red-head text-decoration-none"
          >
            Sign in
          </NavLink>
        </HeadContent>
        <form onSubmit={handleSubmit(registerHandler)}>
          <div className="row row-gap-1 mx-auto" style={{ maxWidth: "500px" }}>
            <div className="col-lg-12">
              <Input
                placeholder="Your First Name"
                id="name"
                label="First Name"
                register={register}
                validation={{ required: "First name is required" }}
                errors={errors}
              />
            </div>
            <div className="col-lg-12">
              <Input
                id="lastname"
                placeholder="Your Last Name"
                label="Last Name"
                register={register}
                validation={{ required: "Last name is required" }}
                errors={errors}
              />
            </div>
            <div className="col-lg-12">
              <Input
                id="email"
                label="Email"
                type="email"
                register={register}
                validation={{
                  required: "Email address is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                }}
                errors={errors}
              />
            </div>
            <div className="col-lg-12">
              <Input
                id="contact"
                label="Contact"
                type="text"
                className="w-100"
                register={register}
                validation={{
                  required: "Contact Number is required",
                  pattern: {
                    value: /^[6789](?!.*(\d)\1{4})\d{9}$/,
                    message: "Provide Valid Contact Number",
                  },
                }}
                errors={errors}
              />
            </div>
            <div className="col-lg-12">
              <Input
                id="aadhar"
                label="Aadhaar"
                type="text"
                register={register}
                validation={{
                  required: "Aadhaar is required",
                  pattern: {
                    value: /^(?!0)(?!.*(\d)\1{4})\d{12}$/,
                    message: "Provide valid Aadhaar Number",
                  },
                }}
                errors={errors}
              />
            </div>
            <div className="col-lg-12">
              <Input
                id="pan"
                label="PAN"
                type="text"
                register={register}
                validation={{
                  required: "PAN is required",
                  pattern: {
                    value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                    message: "PAN must be in the format ABCDE1234F",
                  },
                }}
                errors={errors}
              />
            </div>
            <button type="submit" className="btn red__btn mt-3 w-25 mx-auto">
              Register
            </button>
          </div>

          <ReusableModal
            show={modalContent?.show}
            handleClose={handleModalClose}
            title={modalContent?.title}
            footer={false}
          >
            <p>{modalContent?.content}</p>
          </ReusableModal>
          {/* <button type="button" onClick={handleModalShow}>
              Open Modal
            </button> */}
          <OTPModal
            show={otpModalData.show}
            sendInfo={otpModalData.sendInfo}
            handleClose={otpModalClose}
          ></OTPModal>
        </form>
      </div>
    </>
  );
}
